<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.name"
        v-bind="layout"
        label="搜索标签"
        placeholder="请输入标签名称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.type"
        v-bind="layout"
        key-field="id"
        label="标签类型"
        inner-search
        placeholder="全部类型"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">
        标签列表
        <BjButton v-permission="'user.operate.tag-create'" :class="$style.btn" type="primary" @click="onAdd()">
          <i class="ri-add-line left" />
          添加标签
        </BjButton>
      </p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #action="item">
          <BjLink v-permission="'user.operate.tag-edit'" type="primary" @click="onEdit(item)"> 编辑 </BjLink>
          <a-popconfirm title="确定要删除标签吗" @confirm="onDelete(item)">
            <BjLink v-permission="'user.operate.tag-delete'" type="danger"> 删除 </BjLink>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
    <bj-modal :width="520" title="添加标签" :visible="visible.add" @ok="onOk" @cancel="onCancel">
      <div :class="$style.modal">
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            标签名称
          </a-col>
          <a-col span="18">
            <BjInput v-model="add.name" placeholder="请输入标签名称, 不超过10个字" />
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            标签类型
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="add.type">
              <a-radio :value="1"> 手动标签 </a-radio>
              <a-radio :value="2" disabled> 自动标签 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6"> 标签定义 </a-col>
          <a-col span="18">
            <a-textarea
              v-model="add.describe"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              placeholder="请输入此标签相关的定义描述信息"
            />
          </a-col>
        </a-row>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { userListService } from '@/service'

const service = new userListService()

export default {
  name: 'Home',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        add: false,
      },
      page: 1,
      pageSize: 20,
      total: 0,
      search: {
        name: null,
        type: 0,
      },
      add: {
        name: null,
        type: 1,
        describe: null,
        tag_id: null,
      },
      data: [],
      loading: false,
      typeData: [
        {
          name: '全部类型',
          id: 0,
        },
        {
          name: '手动标签',
          id: 1,
        },
        {
          name: '自动标签',
          id: 2,
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        { title: '标签名称', dataIndex: 'name' },
        {
          title: '标签类型',
          dataIndex: 'type',
          customRender: value => (value === 1 ? '手动标签' : '自动标签'),
        },
        {
          title: '标签人数',
          dataIndex: 'num',
        },
        {
          title: '标签定义',
          dataIndex: 'describe',
          customRender: value => value || '-',
          ellipsis: true,
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getTagList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onEdit(_item) {
      this.add = {
        type: _item.type,
        name: _item.name,
        describe: _item.describe,
        tag_id: _item.id,
      }
      this.visible.add = true
    },
    onAdd() {
      this.add = {
        name: null,
        type: 1,
        describe: null,
        tag_id: null,
      }
      this.visible.add = true
    },
    async onDelete(_item) {
      try {
        await service.deleteTag({
          tag_ids: [_item.id],
        })
        this.$message.success('删除成功')
        this.page = 1
        this.getList()
      } catch (e) {}
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    async onOk() {
      try {
        await service.editTag({
          ...this.add,
        })
        this.$message.success(this.add.tag_id ? '修改成功' : '添加成功')
        this.getList()
        this.visible.add = false
      } catch (e) {}
    },
    onCancel() {
      this.visible.add = false
    },
    init() {
      this.search = {
        name: null,
        type: 0,
      }
      this.onSearch()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;

    .title {
      margin-bottom: 20px;
      font-size: 16px;

      .btn {
        float: right;
      }
    }
  }
}

.modal {
  :global {
    .ant-row {
      margin-bottom: 20px;
    }

    .ant-col-6 {
      line-height: 40px;
    }

    .ant-col-18 {
      line-height: 40px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
